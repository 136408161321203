<template>
  <v-container fill-height fluid>
  </v-container>
</template>

<script>
export default {
  name: 'Complete',
  components: {
  },
  computed: {
  },
  methods: {
    subscribe: function () {
      let scope = this;

      let subData = {
        paypalId: scope.$route.query.subscription_id 
      }

      scope.$store.dispatch('confirmPaypal', subData).then(function (subRes) {
        if (subRes.valid) {
          // subscribed successfully
          scope.$router.push('/upgrade?complete=true').catch(()=>{});
        } else {
          // something went wrong
          scope.$router.push('/account').catch(()=>{});
        }
      })
    }
  },
  mounted () {
    this.subscribe();
  }
}
</script>
<style lang="scss">

</style>
